import React, { useEffect, useState, useCallback, useMemo } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getLastNWords, toggleSelect, translate } from './utils';
import './Discussion.css'
import { useSocket } from './Context/SocketContext';

const Discussion = () => {
    const [message, setMessage] = useState('');
    const [text, setText] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const [lang, setLang] = useState(sessionStorage.getItem('kingLang'));
    const [kingIndustry, setKingIndustry] = useState(sessionStorage.getItem('kingIndustry'));
    const [id] = useState(sessionStorage.getItem('kingId'));
    const [listening, setListening] = useState(false);
    const [time, setTime] = useState(0);
    const navigate = useNavigate();
    const socket = useSocket();

    const commands = useMemo(() => [
        {
            command: 'reset',
            callback: () => resetTranscript(),
        },
        {
            command: 'shut up',
            callback: () => setMessage("I wasn't talking."),
        },
        {
            command: 'Hello',
            callback: () => setMessage('Hi there!'),
        },
    ], []);
    const location = useLocation();

    // Define listenContinuously before the useEffect
    const listenContinuously = useCallback(() => {
        setListening(true);
        SpeechRecognition.startListening({
            continuous: true,
            interimResults: true,
            language: lang,
        });
        console.log('listening')
    }, [lang]);

    const handleNavigate = useCallback(() => {
        // Perform cleanup actions before navigation
        toggleSelect(kingIndustry).then(() => {
            socket.emit("kingDiscussionSelect", "Unregister")
            navigate('/');
        });
    }, [kingIndustry, navigate, socket]);

    useEffect(() => {

        // Check if we should show the message
        if (location.state?.showMessage) {
            setShowMessage(true);

            // Hide the message after 3 seconds
            const timer = setTimeout(() => {
                setShowMessage(false);
            }, 3000);

            // Cleanup the timer on unmount
            return () => {
                toggleSelect(kingIndustry).then(res => {
                    clearTimeout(timer);
                })

            };
        }
    }, [location.state]);

    useEffect(() => {
        listenContinuously(); // Now listenContinuously is initialized before use

        if (socket) {
            console.log('socket listening discussion')

            socket.on('new-king-stream', (data) => {
                console.log('Received new-stream:', data);
                const [receivedMessage, receiveLang, senderId] = data.split('####');
                if (senderId !== id && senderId !== null) {
                    console.log('receive lang:', receiveLang);
                    translate(receivedMessage, receiveLang.split('-')[0], lang).then(res => {
                        setMessage(getLastNWords(res, 10));
                    })
                }
            });

            socket.on('onKingDiscussionSelect', (data) => {
                if (data == 'Unregister') {
                    navigate('/')
                }
            })
        }

        return () => {
            if (socket) {
                console.log('socket unlistening discussion')

                socket.off('new-king-stream')
                socket.off('onKingDiscussionSelect')
            }
            SpeechRecognition.stopListening()
            console.log('Socket disconnected.');
        };
    }, [id, listenContinuously, socket]);

    useEffect(() => {
        const intervalId = setInterval(() => setTime(prevTime => prevTime + 1), 10);
        return () => clearInterval(intervalId);
    }, []);

    const {
        transcript,
        interimTranscript,
        resetTranscript,
    } = useSpeechRecognition({ commands });

    useEffect(() => {
        if (interimTranscript) {
            console.log('Got interim result:', interimTranscript, ' lang: ', lang);
            socket?.emit('king-stream', `${interimTranscript}####${lang}####${id}`);
        }

        if (transcript) {
            console.log('Got result:', transcript);
            setText(transcript);
        }
    }, [transcript, interimTranscript, lang, listening, socket]);

    const hours = Math.floor(time / 360000);
    const minutes = Math.floor((time % 360000) / 6000);
    const seconds = Math.floor((time % 6000) / 100);

    return (
        <div className="container">
            {showMessage && <div className="className">
                <h1>
                    {lang == 'ar' ? 'لقد تم إرسال سؤلك' : 'Your message has been successfully sent!!!'}
                </h1>
            </div>}
            <div className="text-message">
                {message}
            </div>
            <div className="">
                <Link className="chat_button_container" to="/message">
                    <img className='chat_button_icon' src="../chat.png" alt="" srcset="" />
                </Link>
            </div>
            <div className="footer_discussion">
                <div className='button_section' onClick={handleNavigate}>
                    <span className='button_text black'>Back</span>
                </div>
                <p className="stopwatch-time">
                    {hours}:{minutes.toString().padStart(2, "0")}:
                    {seconds.toString().padStart(2, "0")}
                </p>
            </div>
        </div>
    );
};

export default Discussion;
