import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { generateUniqueId, getCategory, getSubCategory, toggleSelect } from './utils';
import Loading from './Loading';
import { useSocket } from './Context/SocketContext';

const King = () => {
    const [translatedLang, setTranslatedLang] = useState(() => sessionStorage.getItem('kingLang') || 'ar');
    const [kingIndustry, setKingIndustry] = useState(() => sessionStorage.getItem('kingIndustry') || '');
    const [kingQuestion, setKingQuestion] = useState(() => sessionStorage.getItem('kingQuestion') || '');
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [selected, setSelected] = useState(false);
    const [selectedCategoryName, setSelectedCategoryName] = useState(''); // Track selected category name
    const [isLoading, setIsLoading] = useState(true);
    
    const navigate = useNavigate();
    const socket = useSocket();

    useEffect(() => {
        const id = generateUniqueId();
        sessionStorage.setItem('kingId', id);
        sessionStorage.setItem('kingLang', translatedLang);
        if(socket){
            console.log('socket listening');
            socket.on("onKingDiscussionSelect", data => {
                console.log('new data: ', data);
                initializeData()
            }) 
        }

        const initializeData = async () => {
            try {
                const categoryResponse = await getCategory();
                setCategories(categoryResponse.data.data);

                const selectedCategory = categoryResponse.data.data.arabic.find(item => item.is_selected === 1);
                if (selectedCategory) {
                    setSelected(true);
                    setKingIndustry(selectedCategory.id);
                    sessionStorage.setItem('kingIndustry', selectedCategory.id);
                    setSelectedCategoryName(selectedCategory.name); // Set category name

                    const subCategoryResponse = await getSubCategory(selectedCategory.id);
                    setSubCategories(subCategoryResponse.data.data);
                    console.log(subCategoryResponse.data.data);
                } else {
                    console.log('unselect');
                    setSelected(false);
                }
            } finally {
                setIsLoading(false);
            }
        };

        initializeData();

        return () => {
            if(socket){
                console.log('socket unlistening');

                socket.off("onKingDiscussionSelect")
            }
        };
    }, [socket]);

    const handleLanguageChange = (e) => {
        const newLang = e.target.value;
        setTranslatedLang(newLang);
        sessionStorage.setItem('kingLang', newLang);
    };

    const handleIndustryChange = async (e) => {
        const newIndustry = e.target.value;
        setKingIndustry(newIndustry);
        sessionStorage.setItem('kingIndustry', newIndustry);

        const subCategoryResponse = await getSubCategory(newIndustry);
        setSubCategories(subCategoryResponse.data.data);

    };

    const handleStartDiscussion = async () => {
        if (!selected) {
            await toggleSelect(kingIndustry);
            sessionStorage.setItem('kingIndustry', kingIndustry);
            console.log('navigating...');
            socket.emit("kingDiscussionSelect", "Register");
            console.log('event register');
        }

        navigate('/discussion');
    };

    if (isLoading) return <Loading />;

    return (
        <div className='container'>
            <div className="logo_container">
                <img className='logo' src='../logo_s2h.png' alt="Logo" />
            </div>
            <h1 className='heading'>السمع بالإبصار</h1>
            <div>
                <label htmlFor="languageDropdown">اختر اللغة: </label>
                <select
                    id="languageDropdown"
                    onChange={handleLanguageChange}
                    value={translatedLang}
                    className="button-16"
                >
                    <option value="en">English</option>
                    <option value="zh">Chinese</option>
                    <option value="fr">Francais</option>
                    <option value="hi">Hindi</option>
                    <option value="ar">Arabic</option>
                </select>
            </div>
            <div>
                {selected && <label htmlFor="industryDropdown">Industry: </label>}
                {selected ? (
                    <h1>{selectedCategoryName}</h1>
                ) : (
                    <></>
                    // <select
                    //     id="industryDropdown"
                    //     onChange={handleIndustryChange}
                    //     value={kingIndustry}
                    //     className="button-16"
                    // >
                    //     {translatedLang === 'ar'
                    //         ? categories.arabic?.map(item => (
                    //             <option key={item.id} value={item.id}>
                    //                 {item.name}
                    //             </option>
                    //         ))
                    //         : categories.english?.map(item => (
                    //             <option key={item.id} value={item.id}>
                    //                 {item.name}
                    //             </option>
                    //         ))}
                    // </select>
                )}
            </div>
            {selected && <div className='button_section' onClick={handleStartDiscussion}>
                <div className="button-white" role="button">
                    <img className='button_icon' src='../teacher.png' alt="Teacher Icon" />
                    <span className='button_text'>ابدأ المحادثة</span>
                </div>
            </div>}
        </div>
    );
};

export default King;
