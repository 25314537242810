import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import King from "./King";
import Discussion from "./Discussion";
import Message from "./Message";
import { SocketProvider } from "./Context/SocketContext";

function App() {
  return (
    <SocketProvider>
      <div className="App">
        <Router>
          <Routes>
            <Route path="/message" element={<Message />} />
            <Route path="/discussion" element={<Discussion />} />
            <Route path="/" element={<King />} />
          </Routes>
        </Router>
      </div>
    </SocketProvider>
  );
}

export default App;
