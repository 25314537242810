import React, { useEffect, useRef, useState, useMemo } from 'react';
import './Message.css';
import { Link, useNavigate } from 'react-router-dom';
import { getSubCategory } from './utils';
import { useSocket } from './Context/SocketContext';

const Message = () => {
    const [chats, setChats] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [text, setText] = useState('');
    const [id] = useState(sessionStorage.getItem('kingId'));
    const [translatedLang, setTranslatedLang] = useState(sessionStorage.getItem('kingLang'));
    // const [isListening, setIsListening] = useState(false);
    const navigate = useNavigate();
    const socket = useSocket();
    const transLangRef = useRef(null);

    const languageOptions = useMemo(() => [
        { value: 'en', label: 'English' },
        { value: 'ar', label: 'عربي' },
        { value: 'es', label: 'Española' },
        { value: 'sh', label: '中国人' },
        { value: 'fr', label: 'Français' },
        { value: 'hi', label: 'हिंदी' },
        { value: 'de', label: 'Deutsch' },
        { value: 'es-MX', label: 'Mexican' },
        { value: 'ja', label: '日本語 (Nihongo)' },
        { value: 'ko', label: '한국어 (Hangugeo)' },
        { value: 'ru', label: 'Русский (Russkiy)' },
        { value: 'pt', label: 'Português' },
        { value: 'it', label: 'Italiano' },
        { value: 'nl', label: 'Nederlands' },
        { value: 'el', label: 'Ελληνικά (Elliniká)' },
        { value: 'tr', label: 'Türkçe' },
        { value: 'he', label: 'עִבְרִית (Ivrit)' },
        { value: 'sv', label: 'Svenska' },
        { value: 'no', label: 'Norsk' },
        { value: 'fi', label: 'Suomi' },
        { value: 'pl', label: 'Polski' },
        { value: 'th', label: 'ภาษาไทย (Phasa Thai)' },
        { value: 'vi', label: 'Tiếng Việt' },
        { value: 'cs', label: 'Čeština' },
        { value: 'hu', label: 'Magyar' },
        { value: 'ro', label: 'Română' },
        { value: 'da', label: 'Dansk' },
        { value: 'fa', label: 'فارسی (Fārsi)' },
        { value: 'id', label: 'Bahasa Indonesia' },
        { value: 'ms', label: 'Bahasa Melayu' },
        { value: 'uk', label: 'Українська (Ukrainska)' },
        { value: 'sr', label: 'Српски (Srpski)' },
        { value: 'hr', label: 'Hrvatski' },
        { value: 'bg', label: 'Български (Balgarski)' },
        { value: 'sk', label: 'Slovenčina' },
        { value: 'lt', label: 'Lietuvių' },
        { value: 'lv', label: 'Latviešu' },
        { value: 'et', label: 'Eesti' },
        { value: 'sl', label: 'Slovenščina' },
        { value: 'tl', label: 'Filipino' },
        { value: 'sw', label: 'Kiswahili' },
        { value: 'af', label: 'Afrikaans' },
        { value: 'is', label: 'Íslenska' },
        { value: 'ga', label: 'Gaeilge' },
        { value: 'cy', label: 'Cymraeg' },
        { value: 'eu', label: 'Euskara' },
        { value: 'gl', label: 'Galego' },
        { value: 'ca', label: 'Català' },
        { value: 'mt', label: 'Malti' },
        { value: 'lb', label: 'Lëtzebuergesch' },
        { value: 'mk', label: 'Македонски (Makedonski)' },
        { value: 'my', label: 'Myanmabhasa' },
        { value: 'ne', label: 'नेपाली' },
        { value: 'si', label: 'සිංහල' },
        { value: 'ml', label: 'മലയാളം' },
        { value: 'pa', label: 'ਪੰਜਾਬੀ' },
        { value: 'mr', label: 'मराठी' },
        { value: 'gu', label: 'ગુજરાતી' },
        { value: 'kn', label: 'ಕನ್ನಡ' },
        { value: 'te', label: 'తెలుగు' },
        { value: 'ta', label: 'தமிழ்' },
        { value: 'uz', label: 'Oʻzbekcha' },
        { value: 'kk', label: 'Қазақ тілі' },
        { value: 'tk', label: 'Türkmençe' },
        { value: 'ky', label: 'Кыргызча' },
        { value: 'hy', label: 'Հայերեն' },
        { value: 'ka', label: 'ქართული' },
        { value: 'az', label: 'Azərbaycan dili' },
        { value: 'sq', label: 'Shqip' },
        { value: 'bs', label: 'Bosanski' },
        { value: 'cnr', label: 'Crnogorski' },
        { value: 'ht', label: 'Kreyòl ayisyen' },
        { value: 'jv', label: 'ꦧꦱꦗꦮ' },
        { value: 'su', label: 'Basa Sunda' },
        { value: 'fj', label: 'Na Vosa Vakaviti' },
        { value: 'sm', label: 'Gagana fa\'a Sāmoa' },
        { value: 'to', label: 'Lea Faka-Tonga' },
        { value: 'mi', label: 'Te Reo Māori' },
        { value: 'hmn', label: 'Hmoob' },
        { value: 'km', label: 'Khmer' },
        { value: 'lo', label: 'Lao' },
        { value: 'am', label: 'Amarəñña' },
        { value: 'so', label: 'Soomaali' },
        { value: 'ti', label: 'Tigrinya' },
        { value: 'om', label: 'Afaan Oromoo' },
        { value: 'zu', label: 'isiZulu' },
        { value: 'xh', label: 'isiXhosa' },
        { value: 'yo', label: 'Yorùbá' },
        { value: 'ig', label: 'Asụsụ Igbo' },
        { value: 'sn', label: 'chiShona' },
        { value: 'ny', label: 'Chichewa' },
        { value: 'tn', label: 'Setswana' },
        { value: 'st', label: 'Sesotho' },
        { value: 'rw', label: 'Ikinyarwanda' },
        { value: 'ku', label: 'Kurmancî' },
        { value: 'ps', label: 'Paṣhto' },
        { value: 'prs', label: 'Darī' },
        { value: 'ug', label: 'Uyghurche' },
        { value: 'bo', label: 'Bod skad' },
        { value: 'ce', label: 'Нохчийн мотт' },
        { value: 'ba', label: 'Башҡорт теле' },
        { value: 'mn', label: 'Монгол' },
        { value: 'tt', label: 'Татар теле' },
        { value: 'udm', label: 'Удмурт кыл' },
        { value: 'chm', label: 'Марий йылме' },
        { value: 'sah', label: 'Саха тыла' },
        { value: 'kom', label: 'Коми кыв' },
        { value: 'khn', label: 'Хӑнты ясанг' },
        { value: 'yrk', label: 'Ненэцяʼ вада' },
        { value: 'ch', label: 'Chamorro' },
        { value: 'mh', label: 'Kajin Majel' },
        { value: 'pau', label: 'A tekoi er a Belau' },
        { value: 'tpi', label: 'Tok Pisin' },
        { value: 'bi', label: 'Bislama' },
        { value: 'tet', label: 'Tetun' },
        { value: 'tvl', label: 'Te Gana Tuvalu' },
        { value: 'Faka\'uvea', label: 'Faka\'uvea' },
        { value: 'ty', label: 'Reo Tahiti' },
        { value: 'gil', label: 'Taetae ni Kiribati' },
        { value: 'ha', label: 'Harshen Hausa' },
        { value: 'wo', label: 'Wolof' },
        { value: 'ln', label: 'Lingála' }
    ], []);
    

    useEffect( () => {
    
        getSubCategory(sessionStorage.getItem('kingIndustry')).then(res => {
            setQuestions(res.data.data);
            console.log(res.data.data);

        })
    
        return () => {
        };
    }, []);

    const sendEvent = (message) => {
        if (socket) {
            console.log('socket', socket);
            socket.emit('king-stream', `${message}####${translatedLang}####${id}`, (response) => {
                console.log(response.status); // ok
                setChats(prevChats => [...prevChats, message]);
                setText('');
                navigate('/discussion', { state: { showMessage: true } });
            });
        } else {
            console.log('not socket');
        }
    };

    const handleSendText = () => {
        if (text.trim()) {
            sendEvent(text)
        }
    };

    const sendDefinedText = (message) => {
        sendEvent(message)
    };

    const handleLanguageChange = (e) => {
        const newLang = e.target.value;
        setTranslatedLang(newLang);
        sessionStorage.setItem('kingLang', newLang);
        transLangRef.current.value = newLang;
    };

    return (
        <div className='wrapper'>
            <div className="header">
                <Link className='back_btn' to="/discussion">
                    <img className='icon' src='../back.png' alt="Send" />
                </Link>
                <h2 className='title'>اسأل سؤالا</h2>
            </div>
            <div className='language_dropdown'>
                <label htmlFor="languageDropdown">لغة</label>
                <select
                    id="languageDropdown"
                    onChange={handleLanguageChange}
                    value={translatedLang}
                    ref={transLangRef}
                    className="button-16"
                >
                    {languageOptions.map(option => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                </select>
            </div>
            <div className="chat_section">
                <div className="message_title">
                    {translatedLang === 'ar' ? 'قائمة الأسئلة السريعة' : 'Quick Questions List'}
                </div>
                <div className="question_container">
                    {translatedLang === 'ar' ? questions.arabic &&  questions.arabic.map((index) => (
                        <div key={index.id} className="question_section">
                                <h3
                                    key={index.id}
                                    onClick={() => sendDefinedText(index.name)}
                                    className='question_text'
                                >
                                    {index.name}
                                </h3>
                        </div>
                    )) : questions.english && questions.english.map((index) => (
                        <div key={index.id} className="question_section">
                                <h3
                                    key={index.id}
                                    onClick={() => sendDefinedText(index.name)}
                                    className='question_text'
                                >
                                    {index.name}
                                </h3>
                        </div>
                    ))}
                </div>
            </div>
            <h1 className='or_text'>
                {translatedLang === 'ar' ? 'او' : 'Or'}
            </h1>
            <div className="footer_message">
                <input
                    className='text_input_message'
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    placeholder={translatedLang === 'ar' ? 'إرسل سؤلك للمعلم كتابتا أو صوتا ' : 'Write your question ( text or voice )?'}
                />
                <button
                    onClick={handleSendText}
                    className='chat_button'
                >
                    <img className='chat_button_img' src='../send.png' alt="Send" />
                </button>
            </div>
        </div>
    );
};

export default Message;
