import axios from "axios";

let speaking = false;

export const speakMessage = (message, lang) => {
    if ('speechSynthesis' in window) {
        const speech = new SpeechSynthesisUtterance();
        console.log('speech: ', speech)
        
        // Set volume (0 to 1)
        speech.volume = 1; // Adjust as needed
        
        // Set pitch (0 to 2)
        speech.pitch = 1; // Adjust as needed
        
        
        // Set language to Arabic
        // speech.lang = speakLang;
        // speech.lang = 'zh-CN';
        speech.lang = lang;
        
        // Set the text in Arabic along with phonetic pronunciation if needed
        speech.text = message;
        
        // Set rate (speed)
        speech.rate = 0.8; // Adjust as needed
        console.log('speech 2: ', speech)
        if (!speaking) {
            speaking = true;
            window.speechSynthesis.speak(speech);
            console.log('speaking speech');
            speech.onend = () => {
                // When speech ends, reset speaking to false
                speaking = false;
            };
            speech.onerror = (event) => {
                // Handle speech synthesis errors here
                console.error('Speech synthesis error:', event.error);
                speaking = false; // Reset speaking flag
            };

        }
    } else {
        console.error('Speech Synthesis API not supported in this browser');
    }
};

export const translate = async (text, lang, translatedLang) => {
    const options = {
        method: 'POST',
        url: 'https://microsoft-translator-text.p.rapidapi.com/translate',
        params: {
            'to[0]': translatedLang,
            'api-version': '3.0',
            from: lang,
            profanityAction: 'NoAction',
            textType: 'plain'
        },
        headers: {
            'content-type': 'application/json',
            // 'X-RapidAPI-Key': '1811d2a188msh0705d23e6c73db3p1785c3jsnec71b517ff21',
            'X-RapidAPI-Key': 'ac277921e7msh532440834f2ecf8p173b0ejsn2c94c8f85413',
            // 'X-RapidAPI-Key': ' ',
            'X-RapidAPI-Host': 'microsoft-translator-text.p.rapidapi.com'
        },
        data: [
            {
                Text: text
            }
        ]
    };

    try {
        const response = await axios.request(options);
        return response.data[0].translations[0].text;
    } catch (error) {
        console.error(error);
    }
}

export const getLastNWords = (inputString, n) => {
    // Split the string into an array of words
    let wordsArray = inputString.trim().split(/\s+/);

    // Extract the last n words
    let lastNWordsArray = wordsArray.slice(-n);

    // Join the words back into a string
    let resultString = lastNWordsArray.join(" ");

    return resultString;
}

export const generateUniqueId = () => {
    const timestamp = Date.now(); // Current timestamp in milliseconds
    const randomNum = Math.random().toString(36).substr(2, 9); // Random string for uniqueness
    return `id-${timestamp}-${randomNum}`;
};

export const getCategory = async () => {
    const options = {
        method: 'GET',
        // url: 'https://see-to-hear-admin.tech-and-beyond.com/web/api/get-category'
        url: 'https://see-to-hear-admin.dreamit.technology/web/api/get-category'
    };

    try {
        const response = await axios.request(options);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const toggleSelect = async (id) => {
    const options = {
        method: 'GET',
        // url: `https://see-to-hear-admin.tech-and-beyond.com/web/api/toggle-select?category_id=${id}`
        url: `https://see-to-hear-admin.dreamit.technology/web/api/toggle-select?category_id=${id}`
    };

    try {
        const response = await axios.request(options);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getSubCategory = async (id) => {
    const options = {
        method: 'GET',
        // url: `https://see-to-hear-admin.tech-and-beyond.com/web/api/get-sub-category?parent_id=${id}`
        url: `https://see-to-hear-admin.dreamit.technology/web/api/get-sub-category?parent_id=${id}`
    };

    try {
        const response = await axios.request(options);
        return response;
    } catch (error) {
        console.error(error);
    }
}
